export default [
    // {
    //     title:'الصفحة الرئيسية',
    //     route:'dashboard-home',
    //     icon:'mdi-home'
    // },
    {
        title:'الشركات',
        route:'dashboard-companies',
        icon:'mdi-view-compact'
    },
    {
        title:'الارشيف',
        icon:'mdi-compare-horizontal',
        children:[
            
            {
                title:'الفواتير الصادرة',
                route:{name:'dashboard-invoices'},
                icon:'mdi-compare-horizontal'
            },
            {
                title:'الفواتير الواردة',
                route: {name:'products-batches'},
                icon:'mdi-compare-horizontal'
            },
        ],
    },
    {
        title:"صندوق البريد",
        icon:"mdi-email",
        route:"dashboard-inbox"
    },
    {
        title:"العملاء",
        icon:"mdi-account-group",
        route:"dashboard-customers"
    },
    {
        title:'المستحقات المالية',
        icon:'mdi-cash',
        children: [
            {
                title:"الرواتب",
                route:{name:'money-salaries'}
            },
            {
                title:"الحوافز",
                route: {name:'money-incentives'}
            }
        ]
    },
    {
        title:'المنتجات',
        icon:'mdi-account-network',
        children: [
            {
                title:"المنتجات المضافة",
                route:{name:'dashboard-products'}
            },
            {
                title:"دفعات المنتجات",
                route: {name:'products-batches'}
            }
        ]
    },
    {
        title:'الاعضاء',
        route:'dashboard-users',
        icon:'mdi-account-settings',
        children:[
            {
                title:'المدراء',
                route:{path:'/users/managers'}
            },
            {
                title:'المشرفين',
                route:{path:'/users/supervisors'}
            },
            {
                title:'الموجهين',
                route:{path:'/users/teamleaders'}
            },
            {
                title:'المندوبين',
                route:{path:'/users/agents'}
            },
        ]
    },
    {
        title:'المركبات',
        route:'dashboard-cars',
        icon:'mdi-car-hatchback'
    },
    {
        title:'المخازن',
        route:'dashboard-warehouses',
        icon:'mdi-store'
    },
    {
        title:'خطط العمل',
        route:'dashboard-calender',
        icon:'mdi-calendar-multiple-check'
    },
    {
        title:'نقاط البيع',
        route:'dashboard-salepoint',
        icon:'mdi-map-marker-multiple'
    },
    {
        title:'المناطق',
        route:'dashboard-regions',
        icon:'mdi-map-marker'
    },
    
]