export default {
  namespaced: true,
  state: {
    warehouse: localStorage.getItem("warehouse") !== null ? JSON.parse(localStorage.getItem("warehouse")) : {},
    isEdit:false,
  },
  mutations: {
    updateWarehouse(state, val) {
      localStorage.setItem("warehouse",JSON.stringify(val));
      state.warehouse = val
    },
    toggleEdit(state,val){
        state.isEdit = val
    }
  },
  actions: {},
}
