import Vue from "vue";
import "./plugins/axios";
import "./icons";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./libs/http";
import config from "./config";
import * as VueGoogleMaps from "vue2-google-maps";
// import PerfectScrollbar from "vue2-perfect-scrollbar";
// import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import VueHtmlToPaper from "vue-html-to-paper";

const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    "https://unpkg.com/kidlat-css/css/kidlat.css",
    "../node_modules/vuetify/dist/vuetify.min.css",
  ],
};

import Print from 'vue-print-nb'
// Global instruction 
Vue.use(Print);
import JsonExcel from "vue-json-excel";
Vue.prototype.$eventBus = new Vue();
Vue.component("downloadExcel", JsonExcel);
Vue.use(VueHtmlToPaper, options);
// Vue.use(PerfectScrollbar);
import style from "./sass/style.scss";
import vueDebounce from "vue-debounce";
import VueMask from "v-mask";
Vue.use(VueMask);
import { VueMaskDirective } from "v-mask";
Vue.directive("mask", VueMaskDirective);
Vue.use(vueDebounce);
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(fas);
import FIcon from "./components/FIcon.vue";
Vue.component("f-icon", FIcon);
Vue.component("font-awesome-icon", FontAwesomeIcon);
import service from "./utils";
Vue.prototype.$service = service;
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBVjI2O1qsyAowAFgHwlH7U_YzGi_DqENs",
    libraries: "places",
    load: {
      region: "IQ",
      language: "ar",
    },
  },
});
import firebaseMessaging from "./firebase";
import i18n from './i18n'

Vue.prototype.$messaging = firebaseMessaging;
// import config from './config'
Vue.prototype.$config = config;
Vue.config.productionTip = false;
if (localStorage.getItem("theme") == null) {
  store.dispatch("resetTheme");
} else {
  const theme = JSON.parse(localStorage.getItem("theme"));
  if (
    theme.colors == undefined ||
    theme.backType == undefined ||
    theme.appbar == undefined ||
    theme.drawer == undefined
  ) {
    store.dispatch("resetTheme");
  }
}
if(localStorage.getItem("version") == null || localStorage.getItem("version") != service.version){
  localStorage.setItem("version", service.version);
  store.dispatch("resetTheme");
}


var mixin = {
  computed:{
    userPermition(){
      return JSON.parse(localStorage.getItem("userInfo")).roles[0]
    }
  }
}

Vue.mixin(mixin)

new Vue({
  router,
  store,
  vuetify,
  style,
  i18n,
  render: (h) => h(App)
}).$mount("#app");
