export default {
  namespaced: true,
  state: {
    manager: {

    },
    isEdit:false,
  },
  mutations: {
    updateManager(state, val) {
      state.manager = val
    },
    toggleEdit(state,val){
        state.isEdit = val
    }
  },
  actions: {},
}
