export default {
  namespaced: true,
  state: {
    product: {

    },
    isEdit:false,
  },
  mutations: {
    updateProduct(state, val) {
      state.product = val
    },
    toggleEdit(state,val){
        state.isEdit = val
    }
  },
  actions: {},
}
