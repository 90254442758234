<template>
  <div :style="background">
    <v-app :style="colors">
      <navigation-bar v-if="isLoggedIn" />

      <v-main class="">
        <v-snackbar
          top
          right
          :timeout="3000"
          color="error"
          style="width: 100; max-width: 100%"
          v-model="snackbar"
        >
          <span
            v-if="
              $store.state.snackbarMessage !== '' &&
                $store.state.snackbarMessage !== null &&
                $store.state.snackbarMessage !== undefined
            "
            v-html="$store.state.snackbarMessage"
          ></span>
          <span v-else> Something went wrong! </span>
          <template v-slot:action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
              <f-icon icon="times-circle" />
            </v-btn>
          </template>
        </v-snackbar>
        <v-snackbar :timeout="2000" top right v-model="notification.show">
          <!-- <v-list > -->
          <v-list-item
            @click="seen(notification.data)"
            :to="toNotif(notification.data)"
          >
            <v-list-item-content>
              <v-list-item-title class="d-flex align-center">
                <v-icon color="primary">mdi-bell</v-icon>
                {{ notification.data.title }}</v-list-item-title
              >
              <v-list-item-subtitle>{{
                notification.data.body
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <!-- </v-list> -->
          <!-- <v-btn flat color="primary" @click.native="show = false">Close</v-btn> -->
        </v-snackbar>
        <app-bar :isLoggedIn="isLoggedIn" />
        <div :class="`wrapper py-5 px-5`">
          <transition name="scale" mode="out-in">
            <router-view />
          </transition>
        </div>
      </v-main>
    </v-app>
  </div>
</template>
<script>
import AppBar from "./AppBar.vue";
// import { isUserLoggedIn } from "@/libs/auth/utils";
import NavigationBar from "./NavigationBar.vue";

export default {
  components: {
    AppBar,
    NavigationBar,
  },
  computed: {
    colors() {
      let style = this.$store.state.style;

      // if (style.colors != undefined) {
      let ret = {};
      Object.keys(style.colors).forEach((key) => {
        ret[`--${key}-color`] = style.colors[key];
      });
      return ret;
      // }
      // return {};
    },
    background() {
      let style = this.$store.state.style;
      if (style.colors.secondary !== undefined) {
        if (style.backType == "gradient") {
          return `background-image: linear-gradient(140deg,${style.colors.secondary} 17%,${style.colors.primary} 67%) !important;`;
        } else if (style.backType == "onlyPrimary") {
          return `background-color: ${style.colors.primary} !important;`;
        } else if (style.backType == "onlySecondary") {
          return `background-color: ${style.colors.secondary} !important;`;
        } else if (style.backType == "image") {
          return `background-image: url(${style.image}) !important; background-size: cover; `;
        }
        if (this.$store.state.dark) {
          return `background-color: ${style.colors.darkBackground} !important;`;
        } else {
          return `background-color: ${style.colors.lightBackground} !important;`;
        }
      }
      return ``;
    },
    isLoggedIn() {
      return this.$store.state.userInfo != null;
    },
    notification() {
      return this.$store.state.notification;
    },
  },
  data() {
    return {
      snackbar: false,
    };
  },
  methods: {
    toNotif(notification) {
      let to = { name: "dashboard-companies" };
      if (notification.type == "company") {
        to = { name: "dashboard-companies" };
      } else if (notification.type == "fms") {
        to = { name: "management-fms", params: { id: notification.toId } };
      } else if (notification.type == "email") {
        to = {
          name: "inbox-read",
          params: {
            id: notification.toId,
          },
        };
      } else if (notification.type == "order") {
        return {
          name: "accounts-order",
          params: { id: notification.toId },
        };
      }
      return to;
    },

    seen(notification) {
      this.$http
        .delete("/Notification/seen/" + notification.id, {
          id: notification.id,
        })
        .then(() => {
          this.getNotifications();
          this.$store.commit("setNotification", {}, false);
        });
    },
  },
  watch: {
    "$store.state.snackbar"() {
      this.snackbar = this.$store.state.snackbar;
    },
    "$store.state.dark"() {
      this.$forceUpdate();
    },
    snackbar() {
      this.$store.commit("UPDATE_SNACKBAR", this.snackbar);
      if (!this.snackbar) {
        this.$store.commit("UPDATE_SNACKBAR_MESSAGE", "");
      }
    },
  },
};
</script>
<style lang="scss">
.item-card {
  width: 225px !important;
  height: 225px !important;
  margin: 8px;
  border: 0 !important;
  border: solid 1px #e0e0e0 !important;
  border-radius: 20px !important;
}
.v-list-item--active {
  background: linear-gradient(
    -45deg,
    var(--primary-color),
    var(--secondary-color)
  ) !important;
  // background: none !important;
  color: white !important;
  box-shadow: 0 0 5px 0.5px var(--primary-color) !important;
}
.v-application {
  padding: 0 !important;
  margin: 0 !important;
  background-repeat: no-repeat !important;
  background-attachment: fixed !important;
  background-color: transparent !important;
}
.company-card {
  cursor: pointer;
  z-index: 2;
  overflow: hidden;
  transition: all ease-out 0.5s;
}
.company-card::before {
  content: "";
  background: inherit;
  position: absolute;
  left: -49px;
  /* z-index: -1; */
  right: 0;
  top: 57px;
  bottom: 0;
  z-index: 0;
  /* box-shadow: inset 0px 93px rgba(255, 255, 255, 0.1); */
  transform: rotate(37deg);
  filter: blur(18px);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0)
  );
  transform: rotate(37deg);
}
.company-card:hover {
  box-shadow: 10px 10px 20px rgba(255, 255, 255, 0.4);
  transform: scale(1.09);
}
.clickable:hover {
  transition: all ease-out 0.5s;
  cursor: pointer;
  box-shadow: 10px 10px 20px rgba(255, 255, 255, 0.4);
  transform: scale(1.09);
}
.company-card:active {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
}
.v-data-table-header {
  border: 4px black solid;
  background-color: #e0e7ef;
  border-radius: 5px !important;
}
.theme--light .v-data-table {
  background-color: transparent !important;
  border-radius: 5px !important;
}
th * {
  color: rgb(0, 0, 0);
  white-space: nowrap !important;
}
th {
  // border:1px solid black;
  border-right: 0;
}
tbody:not(.v-input--switch) {
  background-color: transparent !important;
}
td {
  white-space: nowrap !important;
}
td * {
  white-space: nowrap !important;
}
.v-data-table-header * {
  // color: white;
}
.scale-enter-active,
.scale-leave-active {
  transition: all 0.5s ease;
}

.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
tr:hover {
  background-color: rgb(0, 0, 0, 10%) !important;
  cursor: pointer;
  z-index: 1;
}
// select button inside td
button.v-btn {
  z-index: 7;
}
.v-card:not(.v-sheet--outlined, .transparent) {
  box-shadow: 9px 9px 24px 0 rgb(60 128 209 / 15%) !important;
  border-radius: 20px !important;
}
.shadow {
  box-shadow: 9px 9px 24px 0 rgb(60 128 209 / 15%) !important;
}
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: none !important;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  padding: 0.438rem 1rem;
  /* background-color: #fff; */
  background-clip: padding-box;
}
</style>
