<template>
  <div>
    <v-menu class="py-3" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark text v-bind="attrs" v-on="on">
          
            <f-icon icon="user" />
          <div class="d-flex flex-column justify-end align-content-end mx-4">
            <!-- <span class=""> -->
            {{ $store.state.userInfo.userData.fullName }}
            <!-- </span> -->
            <small class="text-left">{{
              $store.state.userData.roles[0]
            }}</small>
          </div>
        </v-btn>
      </template>
      <v-list>
        <v-list-item :to="{ name: 'settings' }">
          <v-btn text
            ><f-icon class="mx-1" icon="user-cog"></f-icon>Account Settings</v-btn
          >
        </v-list-item>
        <v-list-item>
          <v-btn @click="logout" text
            ><f-icon class="mx-1" icon="sign-out-alt"></f-icon>Log out</v-btn
          >
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import FIcon from '../components/FIcon.vue';
export default {
  components: { FIcon },
  methods: {
    logout() {
      // localStorage.clear();
      this.$store.dispatch("logout");
    },
  },

};
</script>