<template>
  <main-layout />
</template>

<script>

import MainLayout from "./layouts/MainLayout.vue";
export default {
  name: "App",
  components: {
    MainLayout,
  },
  methods: {
    setupNotifications() {
      var id = this.$store.state.userInfo.userData.id;
      this.$messaging
        .requestPermission()
        .then(() => {
          return this.$messaging.getToken({
            vapidKey:
              "BP9BQyccQZL_iFihiWVCngzBlhQ5lGbfbizD9SGvbP9lAnaxIgLsNuvhjqagDG6fvLfjiupeJwYICTm5g3vlVGM",
          });
        })
        .then(function(token) {
          subscribeTokenToTopic(token, id);
          // document.getElementById("token").innerHTML = token;
        })
        .catch(function() {});
      // }

      function subscribeTokenToTopic(token, topic) {
        fetch(
          `https://iid.googleapis.com/iid/v1/${token}/rel/topics/${topic}`,
          {
            method: "POST",
            headers: new Headers({
              Authorization: `key=AAAAqkKveLM:APA91bET0FlDvexooaM0CU1f73LuJis5SOB4j1sJk5BGvcBZhrPxWPQBW9JO2p2icIAddQauO7WR55MGEZZjvJ_W5OJe-RqWPq3X15SZxg8bsXiNvHKFrEkhd6U3zpxuWqvmfsMVaD03`,
            }),
          }
        )
          .then(() => {})
          .catch((error) => {
            console.error(error.result);
          });
        return true;
      }

      this.$messaging.onMessage((payload) => {
        const notificationOption = {
          body: payload.notification.body,
          icon: payload.notification.icon,
          click_action: payload.notification.click_action,
        };

        if (Notification.permission === "granted") {
          var notification = new Notification(
            payload.notification.title,
            notificationOption
          );

          notification.onclick = function(ev) {
            ev.preventDefault();
            window.open(payload.notification.click_action, "_blank");
            notification.close();
          };
        }
      });
      this.$messaging.onTokenRefresh(() => {
        this.$messaging
          .getToken()
          .then(function() {})
          .catch(function() {});
      });
    },
  },
  mounted() {
    if (this.$route.name != "login") {
      if (
        Notification.permission !== "denied" ||
        Notification.permission === "default"
      ) {
        try {
          this.setupNotifications();
        } catch {
          console.log("Notifications not supported");
        }
      }
      
    }
    // IntitalizeFireBaseMessaging();
  },
  created() {
    if (this.$i18n.locale == "ar" || this.$i18n.locale == "kr") {
      this.$vuetify.rtl = true;
    } else {
      this.$vuetify.rtl = false;
    }
  },
  beforeCreate() {},
  watch: {
    $route: function(to, from) {
      to;
      if (from.name == "login") {
        if (
          Notification.permission !== "denied" ||
          Notification.permission === "default"
        ) {
          try {
            this.setupNotifications();
          } catch {
            console.log("Notifications not supported");
          }
        }
      }
    },
  },
  data: () => ({
    messages: [],
    pusher: null,
    channel: null,
  }),
};
</script>
<style lang="scss">
.v-btn {
  letter-spacing: 0 !important;
}
* {
  letter-spacing: 0 !important;
}
.v-application * {
  letter-spacing: 0 !important;
}
</style>
