export default [
    {
        path: "/users/",
        meta: {
          title: "Users",
          icon: "mdi-account-settings",
        },
        name: "hr-users",
        component: () => import("@/views/dashoard/users/Users.vue"),
      },
      {
        path: "/user/:type/:id",
        meta: {
          title: "User Detials",
          icon: "mdi-account-eye",
        },
        name: "hr-user",
        component: () => import("@/views/dashoard/users/User.vue"),
      },
      {
        path: "/users/managers",
        name: "hr-managers",
        meta: {
          title: "Users",
          icon: "mdi-account-settings",
        },
        component: () => import("@/views/dashoard/users/Users.vue"),
      },
      {
        path: "/users/supervisor",
        name: "hr-supervisor",
        meta: {
          title: "Users",
          icon: "mdi-account-settings",
        },
        component: () => import("@/views/dashoard/users/Users.vue"),
      },
      {
        path: "/users/teamleader",
        name: "hr-teamleader",
        meta: {
          title: "Users",
          icon: "mdi-account-settings",
        },
        component: () => import("@/views/dashoard/users/Users.vue"),
      },
      {
        path: "/users/agents",
        name: "hr-agents",
        meta: {
          title: "Users",
          icon: "mdi-account-settings",
        },
        component: () => import("@/views/dashoard/users/Users.vue"),
      },
      {
        path: "/users/delivery",
        name: "hr-delivery",
        meta: {
          title: "Users",
          icon: "mdi-account-settings",
        },
        component: () => import("@/views/dashoard/users/Users.vue"),
      },
      {
        path: "/users/service",
        name: "hr-service",
        meta: {
          title: "Users",
          icon: "mdi-account-settings",
        },
        component: () => import("@/views/dashoard/users/Users.vue"),
      },
      {
        path: "/users/trainer",
        name: "hr-trainer",
        meta: {
          title: "Users",
          icon: "mdi-account-settings",
        },
        component: () => import("@/views/dashoard/users/Users.vue"),
      },
      {
        path: "/users/accounts",
        name: "hr-accounts",
        meta: {
          title: "Users",
          icon: "mdi-account-settings",
        },
        component: () => import("@/views/dashoard/users/Users.vue"),
      },
      {
        path:"/users/salaries",
        name: "hr-salaries",
        meta: {
          title: "Users",
          icon: "mdi-file-document-edit-outline",
          showTitle:false
        },
        component: () => import("@/views/dashoard/users/Users.vue"),
      },
      {
        path:"/users/incentives",
        name: "hr-incentives",
        meta: {
          title: "Users",
          icon: "mdi-file-document-edit-outline",
          showTitle:false
        },
        component: () => import("@/views/dashoard/users/Users.vue"),
      },
      {
        path:"/users/deductions",
        name: "hr-deductions",
        meta: {
          title: "Users",
          icon: "mdi-file-document-edit-outline",
          showTitle:false
        },
        component: () => import("@/views/dashoard/users/Users.vue"),
      },
      {
        path:"/users/financial",
        name: "hr-financial",
        meta: {
          title: "Users",
          icon: "mdi-file-document-edit-outline",
          showTitle:false
        },
        component: () => import("@/views/dashoard/users/Users.vue"),
      },
      {
        path:"/users/paid",
        name: "hr-paid",
        meta: {
          title: "Users",
          icon: "mdi-file-document-edit-outline",
          showTitle:false
        },
        component: () => import("@/views/dashoard/users/Users.vue"),
      },
    ]