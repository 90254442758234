import Vue from "vue";
import VueRouter from "vue-router";
import { isElectron } from "../../utils";
Vue.use(VueRouter);
import { isUserLoggedIn } from "@/libs/auth/utils";
import dashboard from "./routes/dashboard";
import inbox from "./routes/inbox";
import money from "./routes/money";
import store from "@/store";
import accounts from "./routes/accounts";
import hr from "./routes/hr";
import warehouses from "./routes/warehouses";
import sellingPoints from "./routes/selling-points";
import management from "./routes/management";
import print from "./routes/print";
const routes = [
  ...dashboard,
  ...inbox,
  ...sellingPoints,
  ...accounts,
  ...money,
  ...hr,
  ...warehouses,
  ...management,
  ...print,
  {
    path: "/",
    name: "dashboard-home",
    redirect: { name: "dashboard-companies" },
  },
  {
    path: "/login",
    name: "login",

    meta: {
      title: "تسجيل الدخول للنظام",
    },
    component: () => import("@/views/auth/Login.vue"),
  },
  {
    name:"theme",
    path: "/theme",
    component: () => import("@/views/theme/Theme.vue"),
    beforeEnter: (to, from, next) => {
      
      next()
    },
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("@/views/dashoard/users/Settings.vue"),
    meta: {
      title: "Settings",
    },

  }
];
const router = new VueRouter({
  mode: isElectron() ? "hash" : "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, _, next) => {
  if (to.name !== "login" && !isUserLoggedIn()) {
    return next({ name: "login" });
  } else if (to.name == "login" && isUserLoggedIn()) {
    return next({ name: "dashboard-companies" });
  }
  if (isUserLoggedIn()) {
    var role = store.state.roles[store.getters.role];
    // 
    if(role == 3){
      if(to.name.match(/warehouse|order|product/) == null){
          return next({ name: "dashboard-warehouses" });
      }
      return next();
    }
    if (to.name == "dashboard-companies") {
      if (role !== 5 && role > 0 ) {
        return next({ name: "dashboard-company" });
      } else {
        store.commit("UPDATE_COMPANY", null);
      }
    } else {
      if (to.name.search("inbox") == -1 && to.name !== "theme" && to.name !== "settings") {
        if (store.state.company === null) {
          return next({ name: "dashboard-companies" });
        }
      }
    }
  }
  return next();
});
export default router;
