export default {
    namespaced: true,
    state: {
        Accounts: 5,
        Admin: 14,
        Delivery: 12,
        Manager: 1,
        MedicalRepresentative: 10,
        MedicalTeamLeader: 8,
        Owner: 0,
        ProductManager: 4,
        SalesManager: 2,
        SalesRepresentative: 11,
        SalesTeamLeader: 9,
        Service: 13,
        Supervisor: 6,
        Trainer: 7,
        WarehouseManager: 3
    },
    mutations: {
        updateRegion(state, val) {
            state.region = val
        },
        toggleEdit(state, val) {
            state.isEdit = val
        }
    },
    actions: {},
}
