var i18n = require("../../i18n").default;
// let t = (key) => i18n.t(key);
export default [
  {
    path: "/accounts/",
    name: "dashboard-accounts",
    meta: {
      title: i18n.t('accounts'),
      icon: "mdi-file-document-edit-outline",
      showTitle: false,
    },
    component: () => import("@/views/dashoard/accounts/Accounts.vue"),
    // beforeEnter: (to, from, next) => {
    // if (to.params.type == "" || to.params.type == null || to.params.type == undefined) {
    //   next({ name: "accounts-orders", params: { type: "PharmacyOrders" } });
    // } else {
    //   next();
    // }
    // },
  },
  {
    path: "/accounts/accounts-summary",
    name: "accounts-summary",
    meta: {
      title: "Accounts Summary",
      icon: "mdi-cash",
      showTitle: false,
    },
    component: () => import("@/views/dashoard/accounts/Accounts.vue"),
  },
  {
    path: "/accounts/expenses",
    name: "accounts-expenses",
    meta: {
      title: "Expenses",
      icon: "mdi-cash",
      showTitle: false,
    },
    component: () => import("@/views/dashoard/accounts/Accounts.vue"),
  },
  {
    path: "/accounts/payment-vouchers",
    name: "accounts-payment-vouchers",
    meta: {
      title: "Payment Vouchers",
      icon: "mdi-cash",
      showTitle: false,
    },
    component: () => import("@/views/dashoard/accounts/Accounts.vue"),
  },
  {
    path: "/accounts/orders/:type",
    name: "accounts-orders",
    meta: {
      title: i18n.t('accounts'),
      icon: "mdi-file-document-edit-outline",
      showTitle: false,
    },
    component: () => import("@/views/dashoard/accounts/Accounts.vue"),
    beforeEnter: (to, from, next) => {
      if (
        to.params.type == "" ||
        to.params.type == null ||
        to.params.type == undefined
      ) {
        next({ name: "accounts-orders", params: { type: "PharmacyOrders" } });
      } else {
        next();
      }
    },
  },
  {
    path: "/accounts/forwarded-orders/:type",
    name: "accounts-forwarded-orders",
    meta: {
      title: i18n.t('accounts'),
      icon: "mdi-file-document-edit-outline",
      showTitle: false,
    },
    component: () => import("@/views/dashoard/accounts/Accounts.vue"),
    beforeEnter: (to, from, next) => {
      if (
        to.params.type == "" ||
        to.params.type == null ||
        to.params.type == undefined
      ) {
        next({
          name: "accounts-forwarded-orders",
          params: { type: "PharmacyOrders" },
        });
      } else {
        next();
      }
    },
  },
  {
    path: "/order/:type/:id",
    name: "accounts-order",
    meta: {
      title: i18n.t('accounts'),
      icon: "mdi-file-document-edit-outline",
      showTitle: false,
    },
    component: () => import("@/views/dashoard/accounts/orders/Order.vue"),
  },
  {
    path: "/accounts/salaries",
    name: "accounts-salaries",
    meta: {
      title: i18n.t('accounts'),
      icon: "mdi-file-document-edit-outline",
      showTitle: false,
    },
    component: () => import("@/views/dashoard/accounts/Accounts.vue"),
  },
  {
    path: "/accounts/incentives",
    name: "accounts-incentives",
    meta: {
      title: i18n.t('accounts'),
      icon: "mdi-file-document-edit-outline",
      showTitle: false,
    },
    component: () => import("@/views/dashoard/accounts/Accounts.vue"),
  },
  {
    path: "/accounts/deductions",
    name: "accounts-deductions",
    meta: {
      title: i18n.t('accounts'),
      icon: "mdi-file-document-edit-outline",
      showTitle: false,
    },
    component: () => import("@/views/dashoard/accounts/Accounts.vue"),
  },
  {
    path: "/accounts/financial",
    name: "accounts-financial",
    meta: {
      title: i18n.t('accounts'),
      icon: "mdi-file-document-edit-outline",
      showTitle: false,
    },
    component: () => import("@/views/dashoard/accounts/Accounts.vue"),
  },
  {
    path: "/accounts/doctor-dues",
    name: "accounts-doctor-dues",
    meta: {
      title: i18n.t('accounts'),
      icon: "mdi-file-document-edit-outline",
      showTitle: false,
    },
    component: () => import("@/views/dashoard/accounts/Accounts.vue"),
  },
  {
    path: "/accounts/wallet",
    name: "accounts-wallet",
    meta: {
      title: i18n.t('accounts'),
      icon: "mdi-file-document-edit-outline",
      showTitle: false,
    },
    component: () => import("@/views/dashoard/accounts/Accounts.vue"),
  },
  {
    path: "/wallet/collect/:type/:id",
    name: "recipt-collect",
    meta: {
      title: "Collect Dues",
      icon: "mdi-file-document-edit-outline",
      showTitle: false,
    },
    props: (route) => {
      return { ...route.params };
    },
    component: () => import("@/views/dashoard/accounts/wallet/Collect.vue"),
  },
  {
    path: "/accounts/products/all",
    name: "accounts-products",
    component: () => import("@/views/dashoard/accounts/Accounts.vue"),
    props: true,
    meta: {
      title: i18n.t('accounts'),
      icon: "mdi-file-document-edit-outline",
      showTitle: false,
    },
  },
  {
    path: "/products/:id",
    name: "product",
    component: () => import("@/views/dashoard/accounts/products/Detials.vue"),
  },
  {
    path: "/accounts/batches",
    name: "accounts-batches",
    component: () => import("@/views/dashoard/accounts/Accounts.vue"),
    props: true,
    meta: {
      title: i18n.t('accounts'),
      icon: "mdi-file-document-edit-outline",
      showTitle: false,
    },
  },
  {
    path: "/accounts/returns",
    name: "accounts-returns",
    component: () => import("@/views/dashoard/accounts/Accounts.vue"),
    props: true,
    meta: {
      title: i18n.t('accounts'),
      icon: "mdi-file-document-edit-outline",
      showTitle: false,
    },
  },
  {
    path: "/accounts/return/:id",
    name: "accounts-returns-detials",
    component: () => import("@/views/dashoard/accounts/Accounts.vue"),
    props: true,
    meta: {
      title: i18n.t('accounts'),
      icon: "mdi-file-document-edit-outline",
      showTitle: false,
    },
  },
  {
    path: "/accounts/studies",
    name: "accounts-studies",
    component: () => import("@/views/dashoard/accounts/Accounts.vue"),
    props: true,
    meta: {
      title: i18n.t('accounts'),
      icon: "mdi-file-document-edit-outline",
      showTitle: false,
    },
  },
];
