<template>
  <div>
    <v-menu v-model="isOpen" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark text v-bind="attrs" v-on="on">
          <v-badge
            color="error"
            overlap
            v-if="notifications.length > 0"
            :content="notifications.length.toString()"
          >
            <f-icon icon="bell" />
          </v-badge>

          <f-icon v-else size="" icon="bell" />
        </v-btn>
      </template>
      <v-list width="300">
        <template v-if="!loading">
          <template v-if="notifications.length > 0">
            <template v-for="notification in notifications">
              <v-list-item
                @click="seen(notification)"
                :to="toNotif(notification)"
                :key="notification.id"
              >
                <v-list-item-content>
                  <v-list-item-title class="d-flex align-center">
                    <v-icon color="primary">mdi-bell</v-icon>
                    {{ notification.title }}</v-list-item-title
                  >
                  <v-list-item-subtitle>{{
                    notification.body
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider :key="'s' + notification.id"></v-divider>
            </template>
          </template>
          <h4 v-else class="text-center">
            <v-icon color="error">mdi-bell-minus</v-icon>
            No notifications
          </h4>
        </template>
        <v-list-item v-else>
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-list-item>
      </v-list>
    </v-menu>
    
  </div>
</template>
<script>
import Pusher from "pusher-js";
export default {
  name: "Notifications",
  data() {
    return {
      notifications: [],
      loading: true,
      show: true,
      isOpen: false,
      channel: null,
      pusher: null,
      received: {
        id: 0,
        title: "",
        body: "",
        senderName: "",
        isRead: false,
      },
    };
  },
  computed: {},
  watch: {
    // isOpen(val) {
    //   if (val) {
    //     this.getNotifications();
    //   }
    // },
  },
  created() {
    this.getNotifications();
    Pusher.logToConsole = true;
    this.pusher = new Pusher("b4d809eca77d687d414a", {
      cluster: "ap2",
    });
    this.channel = this.pusher.subscribe(" ");
    this.channel.bind("notfiyEventSecret", (data) => {
      this.notifications.unshift(data);
      this.$store.commit("setNotification", data,true);
    
    });
  },
  methods: {
    toNotif(notification) {
      let to = { name: "dashboard-companies" };
      if (notification.type == "company") {
        to = { name: "dashboard-companies" };
      } else if (notification.type == "fms") {
        to = { name: "management-fms", params: { id: notification.toId } };
      } else if (notification.type == "email") {
        to = {
          name: "inbox-read",
          params: {
            id: notification.toId,
          },
        };
      } else if (notification.type == "order") {
        return {
          name: "accounts-order",
          params: { id: notification.toId },
        };
      }
      return to;
    },
    
    seen(notification) {
      this.$http
        .delete("/Notification/seen/" + notification.id, {
          id: notification.id,
        })
        .then(() => {
          this.getNotifications();
        });
    },
    getNotifications() {
      this.loading = true;
      this.$http
        .get("/Notification", {
          params: {
            Seen: false,
            ReceiverId: this.$store.state.userInfo.userData.id,
          },
        })
        .then((res) => {
          this.notifications = res.data.data;
          this.loading = false;
        });
    },
  },
};
</script>
