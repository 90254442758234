export default [
  {
    path: "/warehouses",
    name: "dashboard-warehouses",
    meta: {
      title: "Warehouses",
      icon: "mdi-store",
    },
    component: () => import("@/views/dashoard/warehouses/Warehouses.vue"),
  },
  {
    path: "/warehouse/",
    name: "dashboard-warehouse",
    meta: {
      title: "Warehouses",
      icon: "mdi-store",
    },
    component: () => import("@/views/dashoard/warehouses/Warehouse.vue"),
  },
  {
    path: "/warehouse/products",
    name: "warehouses-products",
    meta: {
      title: "Warehouses",
      icon: "mdi-store",
    },
    component: () => import("@/views/dashoard/warehouses/Warehouse.vue"),
  },
  {
    path: "/warehouse/orders",
    name: "warehouses-orders",
    meta: {
      title: "Warehouses",
      icon: "mdi-store",
    },
    component: () => import("@/views/dashoard/warehouses/Warehouse.vue"),
  },
  {
    path: "/warehouse/veichels",
    name: "warehouses-veichels",
    meta: {
      title: "Warehouses",
      icon: "mdi-store",
    },
    component: () => import("@/views/dashoard/warehouses/Warehouse.vue"),
  },
  {
    path: "/warehouse/delivery",
    name: "warehouses-delivery",
    meta: {
      title: "Warehouses",
      icon: "mdi-store",
    },
    component: () => import("@/views/dashoard/warehouses/Warehouse.vue"),
  },
];
