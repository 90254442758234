export default {
    namespaced: true,
    state: {
        customer: {
  
      },
      isEdit:false,
    },
    mutations: {
      updateCustomer(state, val) {
        state.customer = val
      },
      toggleEdit(state,val){
          state.isEdit = val
      }
    },
    actions: {},
  }
  