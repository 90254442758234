export default [
  {
    path: "/inbox/received",
    name: "dashboard-inbox",
    meta: {
      title: "Received Emails",
      icon: "mdi-email",
    },
    component: () => import("@/views/dashoard/inbox/Inbox.vue"),
  },
  {
    path: "/inbox/received",
    name: "inbox-received",
    meta: {
      title: "Received Emails",
      icon: "mdi-email",
    },
    component: () => import("@/views/dashoard/inbox/Inbox.vue"),
  },
  {
    path: "/inbox/sended",
    name: "inbox-sended",
    meta: {
      title: "Sended Emails",
      icon: "mdi-email",
    },
    component: () => import("@/views/dashoard/inbox/Inbox.vue"),
  },
  {
    path: "/inbox/send",
    name: "inbox-send",
    meta: {
      title: "Send Emails",
      icon: "mdi-email",
    },
    component: () => import("@/views/dashoard/inbox/Inbox.vue"),
  },
  {
    path: "/inbox/read/:id",
    name: "inbox-read",
    component: () => import("@/views/dashoard/inbox/Inbox.vue"),
    meta: {
      title: "Message Preivew",
      icon: "mdi-email",
    },
  },
];
