export default {
    namespaced: true,
    state: {
        agent: {

        },
        isEdit:false,
    },
    mutations: {
        updateAgent(state, val) {
            state.agent = val
        },
        toggleEdit(state,val){
            state.isEdit = val
        }
    },
    actions: {},
}
