export default {
    namespaced: true,
    state: {
      car: {
  
      },
      isEdit:false,
    },
    mutations: {
      updateCar(state, val) {
        state.car = val
      },
      toggleEdit(state,val){
          state.isEdit = val
      }
    },
    actions: {},
  }
  