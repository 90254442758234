export default [
  {
    path: "/management/",
    name: "dashboard-management",
    meta: {
      title: "Management",
      icon: "mdi-plus",
    },
    component: () => import("@/views/dashoard/management/Management.vue"),
  },
  {
    path: "/management/targets",
    name: "management-targets",
    meta: {
      title: "Management",
      icon: "mdi-plus",
    },
    component: () => import("@/views/dashoard/management/Management.vue"),
  },
  {
    path: "/management/plans",
    name: "management-plans",
    meta: {
      title: "Management",
      icon: "mdi-plus",
    },
    component: () => import("@/views/dashoard/management/Management.vue"),
  },
  {
    path: "/management/fms",
    name: "management-fms",
    meta: {
      title: "Management",
      icon: "mdi-plus",
    },
    component: () => import("@/views/dashoard/management/Management.vue"),
  }
];