<template>
  <v-navigation-drawer v-model="$store.state.drawer" v-if="$store.state.sidebar" floating width="300" app style="border: 0px !important" right>
    <v-divider></v-divider>
    <v-list nav>
      <v-list-item
        ><v-list-item-content>
          <v-list-item-title class="text-h5 primary--text font-weight-bold">
            <p class="d-flex">
              
              
              المندوب العلمي</p>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
          <v-list-item
            v-if="item.children === undefined"
            active-class="primary--text"
            link
            v-bind="attrs"
            v-on="on"
            :to="{ name: item.route }"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group no-action v-else>
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="child in item.children"
              :key="child.title"
              active-class="primary--text"
              link
              :to="child.route"
            >
              <v-list-item-content
                ><v-list-item-title>{{
                  child.title
                }}</v-list-item-title></v-list-item-content
              >
            </v-list-item>
          </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import Navigation from "../navigation";
export default {
  data() {
    return {
      items: Navigation,
      mini: true,
    };
  },
};
</script>
<style scoped>
.v-list-group__header__append-icon {
  margin-right: 0px !important;
}
</style>