import jwtDefaultConfig from "./jwtDefaultConfig";
// import store from "@/store";
export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null;

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig };

  // For Refreshing Token
  isAlreadyFetchingtoken = false;

  // For Refreshing Token
  subscribers = [];

  // For Refreshing Token error handling
  subscribersErrors = [];
  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      (config) => {
        // Get token from localStorage
        const token = this.getToken();

        // If token is present add it to request's Authorization Header
        if (token) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      (response) => response,
      (error) => {
        //
        // store.commit('UPDATE_SNACKBAR',true)
        // if(error.response !== null){
        //   this.$store.commit(
        //     "UPDATE_SNACKBAR_MESSAGE",
        //     error.response.data.message
        //   );
        // }
        return Promise.reject(error);
      }
    );
  }

  ontokenFetched(token) {
    this.subscribers = this.subscribers.filter((callback) => callback(token));
  }
  ontokenFetchedError(error) {
    this.subscribersErrors = this.subscribersErrors.filter((callback) =>
      callback(error)
    );
  }
  addSubscriber(callback) {
    this.subscribers.push(callback);
  }
  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName);
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName);
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value);
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value);
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args);
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args);
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    });
  }
}
