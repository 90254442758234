export default {
    namespaced: true,
    state: {
        region: {

        },
        isEdit:false,
    },
    mutations: {
        updateRegion(state, val) {
            state.region = val
        },
        toggleEdit(state,val){
            state.isEdit = val
        }
    },
    actions: {},
}
