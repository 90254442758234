export default {
    namespaced: true,
    state: {
        item: {

        },
        added: false,
        isEdit: false,
    },
    mutations: {
        updateItem(state, val) {
            state.item = val
        },
        toggleDialog(state, dialog) {
            state[dialog] = !state[dialog]
            if (state[dialog]) {
                state.added = false
            }
        },
        toggleAdded(state) {
            state.added = !state.added
        },
        toggleEdit(state) {
            state.isEdit = !state.isEdit
        }
    },
    actions: {},
}
