const numeral = require("numeral");
const moment = require("moment");
import store from "@/store";
export const formatCurrency = (x) => {
  return numeral(x).format("0,0[.]0");
};
export const url = "https://api.dev.scodefield.com/";
export const formatDate = (date, showTime = true, short = false) => {
  var formate = "YYYY/MM/DD";
  if (showTime) {
    formate = "YYYY/MM/DD hh:mmA";
  }
  if (short) {
    formate = "MM/DD";
  }
  var ret = moment(date).format(formate);
  return ret;
};
export const hideFrom = (roles) => {
  var role = store.state.roles[store.getters.role];
  var permissions = roles(role);
  return permissions.indexOf(false) > -1;
};
export const version = "2.0.2";

function formatRecord(items) { 
  let arr = items
  for (let i = 0; i < arr.length; i++) { //loop for all objects
      let currentObject = Object.entries(arr[i]) // convert the current object to list
      let newObject = currentObject // new variable to store the new object
      for (let j = 0; j < currentObject.length; j++) { // loop for the current objectb after convert it to list
          if (Number.isInteger(currentObject[j][1])) { // check if the current value is number to format it
              newObject[j][1] = this.formatCurrency(currentObject[j][1]);
          } 
        //   else if (!isNaN(Date.parse(new Date(currentObject[j][1])))) { // check if the value is date type
        //       console.log(currentObject[j][1])
        //       newObject[j][1] = currentObject[j][1].substring(0, 10)
        //   } 
          else {
              newObject[j][1] = currentObject[j][1];
          }
      }
      arr[i] = Object.fromEntries(newObject) // convert the list to object and set it to the original place
  }
  return arr;
}

export default {
  formatCurrency,
  formatDate,
  hideFrom,
  url,
  version,
  formatRecord
};
