import firebase from 'firebase/app'
import 'firebase/firebase-messaging'
var  messaging;
var firebaseConfig = {
    apiKey: "AIzaSyCPyHMp8lNSNVo6qULe1TDxv6Jl79EdopY",
    authDomain: "scode-erp.firebaseapp.com",
    projectId: "scode-erp",
    storageBucket: "scode-erp.appspot.com",
    messagingSenderId: "731263236275",
    appId: "1:731263236275:web:00dd989c61035563b5d8c6",
    measurementId: "G-PELRGWWT6Q",
};
firebase.initializeApp(firebaseConfig);
try {
    messaging = firebase.messaging();
} catch (e) {
    messaging = e
}
export default messaging
