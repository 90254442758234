import Vue from "vue";
import Vuex from "vuex";
import config from "../config";
import managers from "./managers";
import warehouses from "./warehouses";
import products from "./products";
import cars from "./cars";
import regions from "./regions";
import agents from "./agents";
import customers from "./customers";
import roles from "./roles";
import style from "./style";
import users from "./users";
import http from "../libs/http";
import router from "../router";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isRTL: config.isRTL,
    drawer: true,
    dialog: false,
    dark: config.dark,
    snackbar: false,
    snackbarMessage: "",
    userData:
      localStorage.getItem("userInfo") !== null
        ? JSON.parse(localStorage.getItem("userInfo"))
        : null,
    sideBar: true,
    company:
      localStorage.getItem("company") !== null
        ? JSON.parse(localStorage.getItem("company"))
        : null,
    study: {},
    userInfo:
      localStorage.getItem("userInfo") !== null
        ? JSON.parse(localStorage.getItem("userInfo"))
        : null,
    pharmacy: {},
    salary: {},
    fms: {},
    loading: false,
    notification: {
      show: false,
      data: {},
    },
    itemDetails: {},

  },
  mutations: {
    setDialog(state) {
			state.dialog = !state.dialog
		},
    
    setItemDetails(state, item) {
			state.itemDetails = item
		},
    
    setNotification(state, data, show) {
      state.notification.data = data;
      state.notification.show = show;
    },

    UPDATE_RTL(state, val) {
      state.isRTL = val;
    },
    UPDATE_DARK(state, val) {
      state.dark = val;
    },
    UPDATE_SNACKBAR(state, val) {
      state.snackbar = val;
    },

    UPDATE_SNACKBAR_MESSAGE(state, val) {
      state.snackbarMessage = val;

      if (val != "") {
        state.snackbar = true;
      }
    },
    TOGGLE_DRAWER(state, val) {
      state.drawer = val;
    },
    TOGGLE_SIDEBAR(state, val) {
      state.sideBar = val;
    },
    UPDATE_COMPANY(state, val) {
      localStorage.setItem("company", JSON.stringify(val));
      state.company = val;
    },
    UPDATE_STUDY(state, val) {
      state.study = val;
    },
    UPDATE_PHARMACY(state, val) {
      state.pharmacy = val;
    },
    UPDATE_SALARY(state, val) {
      state.salary = val;
    },
    UPDATE_FMS(state, val) {
      state.fms = val;
    },
    setLoading(state, val) {
      state.loading = val;
    },
  },
  actions: {
    uploadFile({ state }, file) {
      state.loading = true;
      return new Promise((resolve, reject) => {
        if (file != null && typeof file != "string" && file.size > 1) {
          var form = new FormData();
          form.append("files", file);
          http
            .post("/UploadFile/postUploadFiles", form)
            .then((res) => {
              resolve(res.data.result.fileList[0].path);
            })
            .catch((err) => {
              reject(err);
            });
        } else {
          // reject("file is null", file);
          resolve(file);
        }
      });
    },
    logout({ state }) {
      localStorage.removeItem("userInfo");
      localStorage.removeItem("userData");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("company");
      state.userData = null;
      state.userInfo = null;
      state.company = null;
      state.snackbarMessage = "You have been logged out";
      state.snackbar = true;
      router.push("/login");
    },
    resetTheme() {
      localStorage.setItem(
        "theme",
        JSON.stringify({
          colors: {
            primary: "#456990", // orange
            secondary: "#6F96AA", // grey
            accent: "#3CC2E0", // cyan
            error: "#FF606B", // red,
            info: "#2196F3",
            success: "#2ED67B", // gren
            warning: "#FFC107",
            lightBackground: "#F3F5F9",
            darkBackground: "#2F2F2F",
          },
          backType: "default",
          image: null,
          appbar: "light",
          drawer: "light",
        })
      );
      window.location.reload();
    },
  },
  getters: {
    role: (state) => {
      return state.userInfo.roles[0];
    },
  },
  modules: {
    managers,
    products,
    warehouses,
    cars,
    regions,
    agents,
    customers,
    roles,
    style,
    users,
  },
});
