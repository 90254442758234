<template>
  <v-toolbar
    class="my-5 mx-3 rounded-lg shadow"
    v-if="isLoggedIn"
    outlined
    :dark="$store.state.style.appbar == 'dark'"
  >
    <v-toolbar-title>
      <v-btn
        color="primary"
        text
        @click="$store.commit('TOGGLE_DRAWER', !$store.state.drawer)"
      >
        <v-icon size="30">mdi-notification-clear-all</v-icon>
      </v-btn>
      <!-- <v-icon class="ml-2">{{$route.meta.icon}}</v-icon> -->
      <span>
        {{ $route.meta.title }}
      </span>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    
    <locale />
    <v-btn
      color="primary"
      text
      :to="{
        name: 'theme',
      }"
    >
      <f-icon icon="shapes" />
    </v-btn>
    <notifications />
    <v-btn color="primary" text :to="{ name: 'dashboard-companies' }">
      <f-icon icon="home" />
    </v-btn>
    <v-btn color="primary" :to="{ name: 'dashboard-inbox' }" text>
      <f-icon icon="envelope" />
    </v-btn>
    <theme-type
      v-if="$store.state.style.backType == 'default'"
      class="mx-2"
    ></theme-type>
    <user v-if="isLoggedIn" />
    
  </v-toolbar>
</template>
<script>
import ThemeType from "./ThemeType.vue";
import User from "./User.vue";
import Notifications from "./Notifications";
import locale from "./locale";
import Locale from "./locale.vue";
export default {
  props: ["isLoggedIn"],
  created() {
    //
  },
  components: {
    ThemeType,
    User,
    Notifications,
    locale,
    Locale,
  },
  data() {
    return {
      label: "البحث...",
      search: "",
    };
  },
  watch: {
    search() {
      if (this.search != null) {
        if (this.search.length >= 1) {
          this.label = "";
        } else {
          this.label = "البحث...";
        }
      } else {
        this.label = "البحث...";
      }
    },
  },
};
</script>
<style scoped lang="scss">
// @import '~vuetify/src/styles/main.sass';

.v-text-field {
  box-shadow: 0 !important;
}
.v-toolbar {
  // background-color: $secondary !important;
}
</style>
