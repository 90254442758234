import Vue from "vue";
import axios from "axios";
import store from "../../store";
// import service from "../../utils";
const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: "http://localhost:6001/api",
  baseURL: "https://api.scodefield.com/api",
  // baseURL: "https://localhost:5001/api",
  mode: "cors",
  cache: "no-cache",
  credentials: "same-origin",
  headers: {
    Authorization:
      localStorage.getItem("accessToken") !== null
        ? `Bearer ${localStorage.getItem("accessToken")}`
        : "",
    "Content-Type": "application/json",
  },
});
axiosIns.interceptors.response.use(
  function (response) {
    // if (response.config.method === "get") {
    //   if (response.config.url.search("AccountsSummary") == -1) {
    //     if (response.data.data) {
    //       response.data.data.forEach((item, i) => {
    //         Object.keys(item).forEach(key => {
    //           if (Number.isInteger(item[key])) {
    //             response.data.data[i][key] = service.formatCurrency(item[key]);
    //           } else if (!isNaN(Date.parse(new Date(item[key])))) {
    //             return response.data.data[i][key] = service.formatDate(item[key], false);
    //           }
    //         });
    //       });
    //     }
    //     if (response.data.result) {
    //       if (Array.isArray(response.data.result)) {
    //         response.data.result.forEach((item, i) => {
    //           Object.keys(item).forEach(key => {
    //             if (Number.isInteger(item[key])) {
    //               response.data.result[i][key] = service.formatCurrency(item[key]);
    //             } else if (!isNaN(Date.parse(new Date(item[key])))) {
    //               return response.data.result[i][key] = service.formatDate(item[key], false);
    //             }
    //           });
    //         });
    //       } else {
    //         if (response.data.result.data) {
    //           response.data.result.data.forEach((item, i) => {
    //             Object.keys(item).forEach(key => {
    //               if (Number.isInteger(item[key])) {
    //                 response.data.result.data[i][key] = service.formatCurrency(item[key]);
    //               } else if (!isNaN(Date.parse(new Date(item[key])))) {
    //                 return response.data.result.data[i][key] = service.formatDate(item[key], false);
    //               }
    //             });
    //           });
    //         } else {
    //           Object.keys(response.data.result).forEach(key => {
    //             if (Number.isInteger(response.data.result[key])) {
    //               response.data.result[key] = service.formatCurrency(response.data.result[key]);
    //             } else if (!isNaN(Date.parse(new Date(response.data.result[key])))) {
    //               return response.data.result[key] = service.formatDate(response.data.result[key], false);
    //             }
    //           });
    //         }
    //       }
    //     }
    //   }
    // }
    return response;
  },
  function (error) {
    if (error.response) {
      if (error.response.status === 401) {
        store.dispatch("logout");
      }
    }
    return Promise.reject(error);
  }
);
Vue.prototype.$http = axiosIns;
export default axiosIns;
