export default [
    {
        path: "/print/:type/:id/:product",
        name: "print-order",
        meta: {
            title: "Order",
            icon: "mdi-plus",
        },
        component: () => import("@/views/print/Order.vue"),
    }
]