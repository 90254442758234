export default [
  {
    path: "/salaries",
    name: "money-salaries",
    component: () => import("@/views/dashoard/money/salaries/Salaries.vue"),
    meta: {
      title: "الرواتب",
      icon: "mdi-cash",
    },
  },
  {
    path: "/incentives",
    name: "money-incentives",
    component: () => import("@/views/dashoard/money/incentives/Incentives.vue"),
    meta: {
      title: "الحوافز",
      icon: "mdi-cash",
    },
  },
];
