export default {
  projectName: "المندوب العلمي",
  isRTL: false,
  dark: false,
  colors: {
    primary: "#456990", // orange
    secondary: "#6F96AA", // grey
    accent: "#3CC2E0" , // cyan
    error: "#FF606B" ,// red,
    info: "#2196F3",
    success: "#2ED67B",// gren
    warning: "#FFC107",
  },
};
