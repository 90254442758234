export default [
  {
    path: "/dashboard",
    name: "dashboard-home",
    meta: {
      title: "الصفحة الرئيسية",
      icon: "mdi-home",
    },
    component: () => import("@/views/dashoard/Home.vue"),
  },
  {
    path: "/companies",
    name: "dashboard-companies",
    component: () => import("@/views/dashoard/companies/Companies.vue"),
    meta: {
      title: "Companies",
      icon: "mdi-view-compact",
    },
  },
  {
    path: "/company",
    name: "dashboard-company",
    component: () => import("@/views/dashoard/companies/Company.vue"),
    meta: {
      title: "Company detials",
      icon: "mdi-view-compact",
    },
  },

  {
    path: "/salepoint",
    name: "dashboard-salepoint",
    component: () => import("@/views/dashoard/sale-points/SalePoints.vue"),
    meta: {
      title: "نقاط البيع",
      icon: "mdi-map-marker-multiple",
    },
  },
  {
    path: "/salepoint/add",
    name: "add-salepoint",
    meta: {
      title: "اضافة نقطة بيع",
      icon: "mdi-plus",
    },
    component: () => import("@/views/dashoard/sale-points/AddSalePoint.vue"),
  },
  {
    path: "/archive",
    name: "dashboard-archive",
    meta: {
      title: "Archive",
      icon: "mdi-plus",
    },
    component: () => import("@/views/dashoard/archive/Archives.vue"),
  },
  {
    path: "/archive/orders",
    name: "archive-orders",
    meta: {
      title: "Orders",
      icon: "mdi-plus",
    },
    component: () => import("@/views/dashoard/archive/Archives.vue"),
  },
  {
    path: "/archive/products",
    name: "archive-products",
    meta: {
      title: "Orders",
      icon: "mdi-plus",
    },
    component: () => import("@/views/dashoard/archive/Archives.vue"),
  },
  {
    path: "/archive/financial",
    name: "archive-financial",
    meta: {
      title: "Financial",
      icon: "mdi-plus",
    },
    component: () => import("@/views/dashoard/archive/Archives.vue"),
  },
  {
    path: "/archive/doctorReport",
    name: "archive-doctorReport",
    meta: {
      title: "Doctor Report",
      icon: "mdi-plus",
    },
    component: () => import("@/views/dashoard/archive/Archives.vue"),
  },
  {
    path: "/archive/repositoryReport",
    name: "archive-repositoryReport",
    meta: {
      title: "Repository Report",
      icon: "mdi-plus",
    },
    component: () => import("@/views/dashoard/archive/Archives.vue"),
  },
  {
    path: "/archive/pharmacyReport",
    name: "archive-pharmacyReport",
    meta: {
      title: "Pharmacy Report",
      icon: "mdi-plus",
    },
    component: () => import("@/views/dashoard/archive/Archives.vue"),
  },
  {
    path: "/PharmacyOrdersUnpaid",
    name: "PharmcayOrdersUnpaid",
    meta: {
      title: "Pharmacy Unpaid Orders",
      icon: "mdi-plus",
      reportEndpoint: 'Reports/pharmacyOrdersUnpaid',
      excelEndpoint: 'Reports/exportPharmacyOrdersUnpaidToExcel',
      type: 'Pharmacy'
    },
    component: () => import("@/views/OrdersUnpaid.vue"),
  },
  {
    path: "/RepositoryOrdersUnpaid",
    name: "RepositoryOrdersUnpaid",
    meta: {
      title: "Repository Unpaid Orders",
      icon: "mdi-plus",
      reportEndpoint: 'Reports/RepositoryOrdersUnpaid',
      excelEndpoint: 'Reports/exportRepositoryOrdersUnpaidToExcel',
      type: 'Repository'
    },
    component: () => import("@/views/OrdersUnpaid.vue"),
  },
  {
    path: "/DoctorOrdersUnpaid",
    name: "DoctorOrdersUnpaid",
    meta: {
      title: "Doctor Unpaid Orders",
      icon: "mdi-plus",
      reportEndpoint: 'Reports/DoctorOrdersUnpaid',
      excelEndpoint: 'Reports/exportDoctorOrdersUnpaidToExcel',
      type: 'Doctor'
    },
    component: () => import("@/views/OrdersUnpaid.vue"),
  },
  // {
  //   path: "/archive/overdue",
  //   name: "archive-overdue",
  //   meta: {
  //     title: "Payment overdue",
  //     icon: "mdi-plus",
  //   },
  //   component: () => import("@/views/dashoard/archive/Archives.vue"),
  // },
  {
    path: "/archive/FinancialDues/:type",
    name: "archive-financialDues",
    meta: {
      title: "Financial Dues",
      icon: "mdi-plus",
    },
    component: () => import("@/views/dashoard/archive/Archives.vue"),
  },
  {
    path: "/archive/productsSales/:type",
    name: "archive-productsSales",
    meta: {
      title: "Products Sales",
      icon: "mdi-plus",
    },
    component: () => import("@/views/dashoard/archive/Archives.vue"),
  },
  {
    path: "/archive/unpaid/:type",
    name: "archive-unpaid",
    meta: {
      title: "Unpaid Orders",
      icon: "mdi-plus",
    },
    component: () => import("@/views/dashoard/archive/Archives.vue"),
  },
  {
    path: "/sales",
    name: "dashboard-sales",
    meta: {
      title: "Sales",
      icon: "mdi-map-marker",
    },
    component: () => import("@/views/dashoard/sales/Sales.vue"),
  },
  {
    path: "/sales/orders/:type",
    name: "sales-orders",
    meta: {
      title: "Sales",
      icon: "mdi-file-document-edit-outline",
      showTitle: false,
    },
    component: () => import("@/views/dashoard/sales/Sales.vue"),
    beforeEnter: (to, from, next) => {
      if (
        to.params.type == "" ||
        to.params.type == null ||
        to.params.type == undefined
      ) {
        next({ name: "sales-orders", params: { type: "PharmacyOrders" } });
      } else {
        next();
      }
    },
  },
  {
    path: "/follow-up",
    name: "dashboard-follow-up",
    meta: {
      title: "Follow Up",
      icon: "mdi-map-marker",
    },
    component: () => import("@/views/dashoard/follow-up/FollowUp.vue"),
  },
];
