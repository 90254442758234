export default [
  {
    path: "/selling-points/",
    name: "dashboard-selling",
    component: () =>
      import("@/views/dashoard/selling-points/SelllingPoints.vue"),
  },
  {
    path: "/selling-points/doctors",
    name: "selling-doctors",
    component: () =>
      import("@/views/dashoard/selling-points/SelllingPoints.vue"),
  },
  {
    path: "/selling-points/pharmacies",
    name: "selling-pharmacies",
    component: () =>
      import("@/views/dashoard/selling-points/SelllingPoints.vue"),
  },
  {
    path: "/selling-points/company-pharmacies",
    name: "selling-companypharmacies",
    component: () =>
      import("@/views/dashoard/selling-points/SelllingPoints.vue"),
  },
  {
    path: "/selling-points/regoins",
    name: "selling-regoins",
    component: () =>
      import("@/views/dashoard/selling-points/SelllingPoints.vue"),
  },
  {
    path: "/selling-points/stores",
    name: "selling-stores",
    component: () =>
      import("@/views/dashoard/selling-points/SelllingPoints.vue"),
  },
  {
    path: "/selling-points/stores/:id",
    name: "store-detials",
    component: () =>
      import("@/views/dashoard/selling-points/stores/Detials.vue"),
  },
  {
    path: "/selling-points/pharmacy/:id",
    name: "pharmacy-detials",
    component: () =>
      import("@/views/dashoard/selling-points/pharmacies/Detials.vue"),
  },
  {
    path: "/selling-points/doctor/:id",
    name: "doctor-detials",
    component: () =>
      import("@/views/dashoard/selling-points/doctors/Doctor.vue"),
  },
];
